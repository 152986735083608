import { ref } from "vue"; 
import { AlertForm } from '@/types/alert_form';
import { AlertFormItem } from '@/types/alert_form_item';
import { AlertFormNoticeSetting} from '@/types/alert_form_notice_setting';
import { AlertFormsRepository, ProjectIds} from '@/repositories/AlertFormsRepository';
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic';

export const AlertFormsCreateLogic = () => {
  const alertLoading = ref(false);
  const compleate = ref(false);

  const projectIds = ref<ProjectIds>({
    project_ids: []
  })

  const alertFormNoticeSetting = ref<AlertFormNoticeSetting>({
    notice_type: 'email',
    targets: [],
  })

  const alertForm = ref<AlertForm>({
    title: '',
    input_terms_type: '',
    input_terms_timings: [],
    alert_form_items: [],
  })

  const addAlertFormItem = () => {
    const sortOrder = alertForm.value.alert_form_items.length;
    alertForm.value.alert_form_items.push({
      id: undefined,
      key: Math.random(),
      title:'',
      sort_order: sortOrder,
      alert_form_item_fields:[{
        id: undefined,
        key: Math.random(),
        title: '',
        required: false,
        threshold_min: undefined,
        threshold_max: undefined,
        sort_order: 0,
      }]
    });
  }

  const addAlertFormItemField = (alert_form_item: AlertFormItem) => {
    const sortOrder = alert_form_item.alert_form_item_fields.length;
    alert_form_item.alert_form_item_fields.push({
      id: undefined,
      key: Math.random(),
      title: '',
      required: false,
      threshold_min: undefined,
      threshold_max: undefined,
      sort_order:sortOrder,
    })
  }

  const addAlertForm = () => {
    const form: AlertFormItem = {
      id: undefined,
      key: Math.random(),
      title: '',
      sort_order: 0,
      alert_form_item_fields: [{
        id: undefined,
        key: Math.random(),
        title: '',
        required: false,
        threshold_min: undefined,
        threshold_max: undefined,
        sort_order: 0,
      }],
    }
    alertForm.value.alert_form_items.push(form);
  }

  const removeAlertFormItem = (key: number) => {
    for (let i = 0; i < alertForm.value.alert_form_items.length; i++) {
      if (alertForm.value.alert_form_items[i].key == key) {
        alertForm.value.alert_form_items.splice(i, 1);
        break;
      }
    }
  }

  const removeAlertFormItemField = (alert_form_item: AlertFormItem, key: number) => {
    for (let i = 0; i < alert_form_item.alert_form_item_fields.length; i++) {
      if (alert_form_item.alert_form_item_fields[i].key == key) {
        alert_form_item.alert_form_item_fields.splice(i, 1);
        break;
      }
    }
  }

  const create = async (): Promise<AlertForm|false> => {
    alertLoading.value = true;
    const repository: AlertFormsRepository = new AlertFormsRepository();
    try {
      const savealertForm = await repository.add(alertForm.value, alertFormNoticeSetting.value,  projectIds.value);
      alertLoading.value = false;
      compleate.value = true;
      return savealertForm;
    } catch (e: any) {
      useExceptionMessage(e, "失敗しました。再読み込みをしてお試し下さい");
      return false;
    }
  }

  const up = (alertForm_key: number) => {
    for (let i = 0; i < alertForm.value.alert_form_items.length; i++) {
      if (alertForm.value.alert_form_items[i].key == alertForm_key) {
        if (i == 0) {
          break;
        }
        alertForm.value.alert_form_items.splice(i - 1, 2, alertForm.value.alert_form_items[i], alertForm.value.alert_form_items[i - 1]);
        break;
      }
    }
  }

  const down = (alertForm_key: number) => {
    for (let i = 0; i < alertForm.value.alert_form_items.length; i++) {
      if (alertForm.value.alert_form_items[i].key == alertForm_key) {
        if (i == alertForm.value.alert_form_items.length - 1) {
          break;
        }
        alertForm.value.alert_form_items.splice(i, 2, alertForm.value.alert_form_items[i + 1], alertForm.value.alert_form_items[i]);
        break;
      }
    }
  }

  const AlertFormsCreatetateReturn = () => {
    return {
      up,
      down,
      create,
      compleate,
      alertForm,
      alertLoading,
      addAlertForm,
      removeAlertFormItem,
      removeAlertFormItemField,
      addAlertFormItem,
      addAlertFormItemField,
      alertFormNoticeSetting,
      projectIds
    }
  }
  return {
    AlertFormsCreatetateReturn,
    ...AlertFormsCreatetateReturn()
  }
}